.welcome-box {
  min-height: 20em;
  margin: 0 2em;
  padding: 2.5em 0 0 0;
  background-image: url(/img/car.jpg);
  background-size: cover;
  background-position: center;
  border: 6px solid #212121;
  border-radius: 10px;

  h2 {
    max-width: 7em;
    margin: 0 auto 2em;
    text-align: center;
    font-size: 4rem;
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    font-weight: bold;
    text-shadow: 
      0px 2px 0px #000000, 
      0px 4px 0px #000000, 
      -1px 6px 8px #000000;
    line-height: 1em;
    color: #fff;
  }
}

.main-contact-form {
  max-width: 30em;
  margin: -3em auto 0;
  padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  color: #000;

  h3 { text-align: center }

  label {
    width: 100%;
    margin-bottom: 1em;
  }

  label > h4 {
    font-size: .85em;
    font-weight: bold;
  }
}

@media only screen and (max-width: 550px) {
  .welcome-box {
    margin: 0 -2em;
    padding: 2em;
  }
}
