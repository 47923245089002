body {
  margin: 0;
  font-family: 'Cairo';
  background-color: #101010;
  background-image: url(/img/spiration.png);
  color: #fff;
}

.page-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1em;
}
