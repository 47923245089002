.page-head {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.main-head-top {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0 .5em;
    flex-basis: 6em;
    font-weight: normal;
    font-size: 2rem;
  }
}

.main-head-bottom {
  margin-top: 2.5em;
  padding: .5em 1em;
  display: flex;
  justify-content: flex-end;

  nav {
    margin: 0 2em;
    display: flex;
    justify-content: flex-end;
    background-color: #2f2f2f;
    border-radius: 6px;

    a {
      margin: -.25em .5em .5em;
      padding: .5em;
    }
  }
}

.main-head-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  p {
    max-width: 8em;
    margin: 1em 1em 0;
    position: relative;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1em;
    text-decoration: underline;
    cursor: pointer;

    &:focus,
    a:focus {
      outline: none;
      color: #a7a7a7;
      text-decoration-color: #a7a7a7;
    }

    &:active,
    a:active {
      outline: none;
      text-decoration-color: blue;
    }

    &:before {
      content: attr(aria-label);
      display: inline-block;
      position: absolute;
      top: -1.25em;
      font-weight: normal;
      font-size: .75em;
      color: #fff;
    }

    a,
    a:visited { color: inherit }
  }
}

@media only screen and (max-width: 550px) {
  .main-head-contact {
    flex-direction: column;

    p:last-of-type {
      display: none;
    }
  }

  .main-head-bottom,
  .main-head-bottom > nav {
    justify-content: center;
  }
}